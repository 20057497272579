import { requests } from "@/utils/requests";

export const getAccounts = async (user_uuid) => {
  let query = "";
  if (user_uuid != null) query = `user_uuid=${user_uuid}`;
  const data = await requests({
    url: `/api/admin/account?${query}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const newAccount = async ({
  user_id,
  user_pw,
  user_name_first_kor,
  user_name_last_kor,
  user_name_first_eng,
  user_name_last_eng,
  user_mail_id,
  user_mail_domain,
  user_phone_number,
  user_level,
}) => {
  const data = await requests({
    url: "/api/admin/account",
    method: "POST",
    data: {
      user_id,
      user_pw,
      user_name_first_kor,
      user_name_last_kor,
      user_name_first_eng,
      user_name_last_eng,
      user_mail_id,
      user_mail_domain,
      user_phone_number,
      user_level,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const putAccount = async (
  user_uuid,
  {
    user_id,
    user_pw,
    user_name_first_kor,
    user_name_last_kor,
    user_name_first_eng,
    user_name_last_eng,
    user_mail_id,
    user_mail_domain,
    user_phone_number,
    user_level,
  }
) => {
  let query = "";
  if (user_uuid != null) query = `user_uuid=${user_uuid}`;
  const data = await requests({
    url: `/api/admin/account?${query}`,
    method: "PUT",
    data: {
      user_id,
      user_pw,
      user_name_first_kor,
      user_name_last_kor,
      user_name_first_eng,
      user_name_last_eng,
      user_mail_id,
      user_mail_domain,
      user_phone_number,
      user_level,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delAccount = async (user_uuid) => {
  let query = "";
  if (user_uuid != null) query = `user_uuid=${user_uuid}`;
  const data = await requests({
    url: `/api/admin/account?${query}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  getAccounts,
  newAccount,
  putAccount,
  delAccount,
};
