import { requests } from "@/utils/requests";

export const getUserLevel = async () => {
  const data = await requests({
    url: `/api/public/presets/level/user`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getCompanyLevel = async () => {
  const data = await requests({
    url: `/api/public/presets/level/company`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getSuccessOfferings = async () => {
  const data = await requests({
    url: `/api/public/presets/successofferings`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getPriority = async () => {
  const data = await requests({
    url: `/api/public/presets/priority`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export default {
  getUserLevel,
  getCompanyLevel,
  getSuccessOfferings,
};
